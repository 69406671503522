import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { List, Row, Col } from "antd"

const PorteeCard = ({ portee, logo }) => {
  const onErrorSrc = event => {
    event.target.src = logo.childImageSharp.fluid.src
    event.target.style.width = "200px"
    event.target.style.margin = "10px 0"
  }
  return (
    <List.Item className="portees_card">
      <Link to={`/${portee.slug}`} style={{ width: "100%" }}>
        <Row
          type="flex"
          justify="start"
          gutter={[
            { xs: 0, md: 16 },
            { xs: 16, md: 0 },
          ]}
          style={{ width: "100%" }}
        >
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="portees_card_img">
              {portee.photo ? (
                <img
                  src={portee.photo}
                  alt="Elevage du Cedre Enchante"
                  width="100%"
                  onError={event => onErrorSrc(event)}
                />
              ) : (
                <Img
                  fluid={logo.childImageSharp.fluid}
                  style={{ width: "200px" }}
                  alt="Logo Le Cèdre Enchanté"
                />
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <h3>
              {portee.father} et <br />
              {portee.mother}
            </h3>
            <p style={{ width: "100%", textAlign: "center" }}>
              Naissance le {portee.date}
            </p>
            {portee.males > 0 && (
              <div className="portees_chiots portees_chiots_males">
                {portee.males}{" "}
                {portee.males > 1 ? "mâles disponibles" : "mâle disponible"}
              </div>
            )}
            <br />
            {portee.femelles > 0 && (
              <div className="portees_chiots portees_chiots_femelles">
                {portee.femelles}{" "}
                {portee.femelles > 1
                  ? "femelles disponibles"
                  : "femelle disponible"}
              </div>
            )}
          </Col>
        </Row>
      </Link>
    </List.Item>
  )
}

export default PorteeCard
