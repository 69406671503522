import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { List } from "antd"
import PorteeCard from "../components/portee-card"
import AdCard from "../components/ad-card"

const Portees = ({ data }) => {
  const { title, slug, portees } = data.markdownRemark.frontmatter

  const races = []

  if (portees != null) {
    portees.forEach(portee => {
      if (!races.includes(portee.race)) {
        races.push(portee.race)
      }
    })
  }

  return (
    <Layout page={slug} bgColorDark={true}>
      <SEO
        title={title}
        keywords={[
          `Elevage`,
          `Cedrenchante`,
          `chiens`,
          `chats`,
          `chiots`,
          `chatons`,
          `berger des shetland`,
          `chihuahua`,
          `epagneul tibetain`,
          `british shorthair`,
        ]}
      />
      <div className="portees">
        {races.map((race, i) => {
          let porteesByRace = portees
            .filter(portee => portee.race.toString() === race.toString())
            .map((portee, index) => {
              return <PorteeCard key={index} portee={portee} logo={data.logo} />
            })

          return (
            <div key={i}>
              {/* <AdCard /> */}
              <h2>Chiots {race}</h2>
              <List itemLayout="horizontal">{porteesByRace}</List>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Portees

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        portees {
          date
          mother
          father
          slug
          males
          femelles
          race
          photo
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
